import { Grid, Typography, styled } from "@mui/material";
import Box from "@mui/material/Box";
import { animated, config, useSpring } from "@react-spring/web";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Contact from "../components/Contact";
import bgmain7 from "../img/bgmain7.jpg";
import bgmain3 from "../img/bgmain3.jpg";
import lakesbg44 from "../img/lakesbg44.png";
import Locations from "./Locations";
import Offer from "./Offer";
import Reviews from "./Reviews";
import Values from "./Values";

const TealButton = styled(Link)({
  textDecoration: "none",
  display: "inline-flex",
  justifyContent: "center",
  border: "2px solid #3A959B",
  color: "#3A959B",
  margin: "10px",
  padding: "5px 20px",
  width: "240px",
  backgroundImage: "linear-gradient(#3A959B, #3A959B)",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "white",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
});
const GreyButton = styled(Link)({
  textDecoration: "none",
  display: "inline-flex",
  justifyContent: "center",
  border: "2px solid #333333",
  color: "#333333",
  padding: "5px 20px",
  margin: "10px",
  width: "240px",
  backgroundImage: "linear-gradient(#333333, #333333)",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "white",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
});

function Main() {
  const [isChat, toggleIsChat] = useState("Chatham");

  const spring1 = useSpring({
    from: { opacity: "0%" },
    to: { opacity: "100%" },
    delay: 200,
    config: config.molasses,
  });
  const spring2 = useSpring({
    opacity: 1,
    delay: 700,
    reset: true,
    transform: "translateY(0px)",
    from: {
      opacity: 0,
      transform: "translateY(250px)",
    },
  });
  const spring3 = useSpring({
    from: { color: "#222222" },
    to: { color: "#3A959B" },
    delay: 1500,
    config: config.molasses,
  });

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          minHeight: "550px",
          backgroundImage: `url(${lakesbg44})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: "150px",
          paddingTop: { xs: "100px", sm: "0px" },
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: "4%",
            height: "100%",
            width: "100%",
          }}
        >
          <Grid item xs={0} md={5}></Grid>
          <Grid item container xs={12} md={7} justifyContent="center">
            <Grid
              container
              item
              justifyContent="center"
              sx={{
                maxWidth: "600px",
                backdropFilter: { xs: "blur(5px)", md: "none" },
                padding: "20px",
              }}
            >
              <animated.div style={spring1}>
                <Grid item container>
                  <Typography variant="h3">
                    Empowering
                    <animated.span style={spring3}> smiles</animated.span> with
                    surgical expertise
                  </Typography>
                  <Typography
                    sx={{ marginTop: "10px" }}
                    variant="h6"
                    color="text.medium"
                  >
                    Rest assured you're in good hands with our exceptional team
                    and state-of-the-art equipment.{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-around"
                  sx={{ marginTop: "10px" }}
                >
                  <TealButton to="about">
                    <Typography variant="h6">Learn More</Typography>
                  </TealButton>
                  <GreyButton to="surgery">
                    <Typography variant="h6">Our Services</Typography>
                  </GreyButton>
                </Grid>
              </animated.div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <animated.div style={spring2}>
        <Contact isChat={isChat} toggleIsChat={toggleIsChat} />
      </animated.div>
      <Box sx={{ minHeight: "200vh", width: "100%", backgroundColor: "white" }}>
        <Offer />
        <Values />
        <Reviews />
        <Locations isChat={isChat} toggleIsChat={toggleIsChat} />
      </Box>
    </>
  );
}

export default Main;
